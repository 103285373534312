body {
  margin:5px;
  align-items: center;
  margin-left: 10em;;
  background-color:lightgray;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.container{
  margin-top: 10em;
  margin-left:20em;
  
}
.container1{
  margin-left: 25em;
  margin-top: 5em;
  height: 200px;
  width: 300px;
  background-color:rgb(28, 142, 181);
  color: black;
  padding: 30px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
